import { useContext } from "react"
import ThemeContext from "../../../context/ThemeContext"
import Layout from "../../../components/layout"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../../../components/seo"
import t from '../../../services/GetTranslationValue'
import GameCards from "../../../components/game-cards/game-cards"
import RichText from "../../../components/text-helpers/rich-text"
import localize from "../../../components/localize"
import BlockContent from "../../../components/text-helpers/portable-text"

const Content = ({ translations, locale, gameProvider, games, title}) => {

  return (
    <div>
      <div className="gambleGeneralWrapper">
        <h1>{title}</h1>
        { gameProvider._rawFreespinContent &&
            <BlockContent blocks={gameProvider._rawFreespinContent}/>
        }
      </div>
      <GameCards isPage={true} games={games} provider={gameProvider.name} translations={translations} locale={locale} />
      { gameProvider._rawFreespinFooter &&
        <div className="gambleGeneralWrapper"><BlockContent blocks={gameProvider._rawFreespinFooter} /></div>
      }
    </div>
  )
}

const GameProviderFreespinsItem = ({ data, pageContext, location }) => {
  let gameProvider = data.sanityGameProvider
  const { locale, alternateLocaleSlugs } = pageContext

  let { translations, bonuses } = useContext(ThemeContext)
  bonuses = bonuses.filter(x => x.gameProviders.some(y => y.name === gameProvider.name));

  let games = data.allSanityGame.nodes;
  let title = gameProvider.freespinHeader ? gameProvider.freespinHeader : `${gameProvider.name} ${t.get(translations, 'game-provider-casinos-header')}`;
  let seoTitle = gameProvider.freespinSeoTitle ? gameProvider.freespinSeoTitle : `${gameProvider.name} ${t.get(translations, 'game-provider-casinos-seotitle')}`;

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} bonuses={bonuses} alternateSlug={alternateLocaleSlugs}
           title={seoTitle} gameProvider={gameProvider} description={gameProvider.freespinSeoMeta} />

      <Content
        title={title}
        translations={translations}
        locale={locale}
        games={games}
        gameProvider={gameProvider}
      />
    </Layout>
  )
}

export default localize(GameProviderFreespinsItem)
export const pageQuery = graphql`
  query GameProvidersFreespinQuery($providerName: String!) {
    sanitySilo (_id: {eq: "2490d32a-1555-42d2-aab6-970ff9702841"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGameProvider(
      name: { eq: $providerName }
    ) {

        _rawFreespinHeader(resolveReferences: {maxDepth: 15})
        _rawFreespinContent(resolveReferences: {maxDepth: 15})
        _rawFreespinFooter(resolveReferences: {maxDepth: 15})
    freespinSeoTitle{
        ...LocaleString
    }
    freespinSeoMeta{
        ...LocaleString
    }
      name
    }

      allSanityGame(filter: {gameProvider: {name: {eq: $providerName}}}){
          nodes {
              name
              logoImage {
                  asset {
                      fluid {
                          ...GatsbySanityImageFluid
                      }
                  }
              }
          }
      }
  }
  
`
